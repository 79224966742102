<template>
  <v-container>
    <v-card>
      <v-data-table v-bind="bind.table" :search="search">
        <template #top>
          <table-top v-bind="bindChain.tableTop" v-on="onChain.tableTop" />
        </template>

        <template #item.actions="{ item }">
          <table-actions
            v-bind="bindChain.tableActions"
            v-on="onChain.tableActions(item)"
            :deleteDisabled="item[itemDisabled]"
          />
        </template>
      </v-data-table>
    </v-card>
    <Form v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Form from "@/components/Forms/cruds/tipoProducto.vue";
import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "TipoProductoCrudPage",
  mixins: [PageMixin],
  components: {
    Form,
  },
  data: () => ({
    headers: [
      { text: "Código", value: "codigo", align: "left" },
      {
        text: "Tipo Producto",
        value: "tipo_producto_descripcion",
        align: "left",
      },
      { text: "Segmento", value: "segmento_descripcion", align: "left" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    tableList: [],
    itemId: "tipo_producto",
  }),

  computed: {
    items() {
      return this.tableList;
    },
  },

  methods: {
    ...mapActions("NewProduct", ["getTipoProductos", "deleteTipoProd"]),

    async setup() {
      this.loading = true;
      try {
        const response = await this.getTipoProductos();
        /* console.log(res.data.data.result); */
        this.tableList = response;
      } catch (error) {
        this.snackbar(error.info);
      }
      this.loading = false;
    },

    saveItem() {
      this.closeForm();
      this.setup();
    },
    deleteMethod() {
      return this.deleteTipoProd;
    },
  },
};
</script>
